import React from "react";
import styled from "styled-components/macro";

import { NavLink } from "react-router-dom";

import {
  List as MuiList,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
} from "@material-ui/core";

import * as NavigationConstants from "../constants/NavigationConstants";

const Wrapper = styled.div`
  padding-bottom: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const List = styled(MuiList)`
  margin: 0 30px;
  display: inline-block;
`;

const ListItem = styled(MuiListItem)`
  width: auto;
  background-color: transparent !important;
  margin: 0;
  padding: 0;
  display: inline-block;
`;

const ListItemSeparated = styled(MuiListItem)`
  width: auto;
  background-color: transparent !important;
  margin: 0 15px 0 0;
  padding: 0;
  display: inline-block;
  position: relative;

  &:after {
    width: 1px;
    height: 11px;
    background-color: ${(props) => props.theme.palette.text.secondary};
    display: block;
    position: absolute;
    top: 2px;
    right: -8px;
    content: '';
  }

  &:last-child {
    margin: 0;

    &:after {
      display: none;
    }
  }
`;

const ListItemText = styled(MuiListItemText)`
  margin: 0;

  span {
    margin: 0;
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 12px;
    line-height: 15px;
  }
`;

function GlobalFooter() {


  return (
    <Wrapper>
      <List>
        <ListItemSeparated button={true} component="a" href="https://www.smartcoreapp.com/privacy-policy/" target="_blank">
          <ListItemText primary="Privacy Policy" />
        </ListItemSeparated>
        <ListItemSeparated button={true}
          component={NavLink}
          to={NavigationConstants.EULA}
          exact
          target="_blank">
          <ListItemText primary="End User License Agreement" />
        </ListItemSeparated>
      </List>
      <List>
        <ListItem button={true} component="a" href="https://smartcoreapp.com" target="_blank">
          <ListItemText
            primary={`Copyright © ${new Date().getFullYear()} - SmartCore LLC`}
          />
        </ListItem>
      </List>
    </Wrapper>
  );
}

export default GlobalFooter;
