import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useHistory, NavLink } from "react-router-dom";

// import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  Typography as MuiTypography,
} from "@material-ui/core";


// SVXVY imports
import * as NavigationConstants from '../../constants/NavigationConstants';
import LoginForm from "../../components/company/userManagement/LoginForm";
import PasswordResetForm from "../../components/company/userManagement/PasswordResetForm";

// renderings
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0.5px 0 0 rgba(0,0,0,0.2);
  margin: 0 0 30px 0;
  padding: 22px 15px 30px 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 22px 30px 30px 30px;
  }
`;
const Typography = styled(MuiTypography)(spacing);


const Home = props => {
  const history = useHistory();
  const loggedInUser = useSelector(state => state.auth.person);

  const [showResetForm, setShowResetForm] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  useEffect(() => {
    if (loggedInUser != null) {
      const additionalProfiles = loggedInUser.additionalProfiles;
      if (additionalProfiles && additionalProfiles.length === 0) {
        // const startOfUrl = "/" + additionalProfiles[0].sanitizedCompanyName + '/';
        history.push("/"+ NavigationConstants.REGISTER);
      }
      else {
        history.push("/" + NavigationConstants.LOOKUP_COMPANY);
      }
    }
  }, [loggedInUser, history])

  return (
    <React.Fragment>
      <Helmet>
        <title>SmartCore</title>
      </Helmet>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
        {!showResetForm &&
          <Card p={4} >
            <Box>
              <Typography variant="h2" component="h2" style={{ marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Sign In</Typography>
              {resetEmail && <Typography variant="body2" style={{ margin: '0 0 12px 0', fontSize: '14px', lineHeight: '18px' }}>
                Use the authention code sent to you in the password field below.
              </Typography>}
            </Box>
            <LoginForm
              email={resetEmail}
              additionalComponents={
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => setShowResetForm(true)}
                    color="primary"
                    style={{ margin: '-7px 0 26px 0', padding: 0 }}
                  >
                    <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }}>Forgot your Password?</Typography>
                  </Button>
                </Box>
              }
            />
          </Card>
        }
        {showResetForm &&
          <React.Fragment>
            <Card p={4} >
              <PasswordResetForm
                onSuccess={(value) => { setResetEmail(value); setShowResetForm(false) }}
              />
            </Card>
            <Box display="flex" justifyContent="center">
              <Button
                onClick={() => setShowResetForm(false)}
                color="primary"
                style={{ margin: '-6px 0 0 0', padding: 0 }}
              >
                <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }}>Back to Sign In</Typography>
              </Button>
            </Box>
          </React.Fragment>
        }
        {!showResetForm &&
          <Card mt={4} p={4}>
            <Typography variant="body2" style={{ margin: '0 0 6px 0', fontSize: '14px', lineHeight: '18px' }}>
              Register your business to get started today for free!
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              mt={4}
              p={2}
              to={"/" + NavigationConstants.REGISTER}
              component={NavLink}
            >
              Create a New Business Account
            </Button>
          </Card>
        }
      </Box>

    </React.Fragment >
  );
}

export default Home;
